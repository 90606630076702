<template>
  <VaSidebar v-model="writableVisible" :width="sidebarWidth" :color="color" :minimized-width="sidebarMinimizedWidth">
    <SiteSwitcher class="mx-2 mb-2" style="width: 170px" v-if="!isSidebarMinimized"/>
    <div class="va-title" style="color: var(--va-primary); font-size: 9px; line-height: 14px;margin-left: 10px;">
      Menu
    </div>
    <VaAccordion v-model="value" multiple>
      <VaCollapse v-for="(route, index) in navigationRoutes.routes" :key="index">
        <template #header="{ value: isCollapsed }">
          <VaDivider v-if="route.meta.divider" />
          <VaSidebarItem
            :to="route.children ? undefined : { name: route.name }"
            :active="routeHasActiveChild(route)"
            :active-color="activeColor"
            :text-color="textColor(route)"
            :aria-label="`${t(route.displayName)}`"
            role="button"
            hover-opacity="0.10"
          >
            <VaSidebarItemContent class="py-3 pr-2 pl-4">
              <VaIcon
                v-if="route.meta.icon"
                aria-hidden="true"
                :name="route.meta.icon"
                size="20px"
                :color="iconColor(route)"
              />
              <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                {{ t(route.displayName) }}
                <VaIcon v-if="route.children" :name="arrowDirection(isCollapsed)" size="20px" />
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
        </template>
        <!-- <template #body>
          <div v-for="(childRoute, index2) in route.children" :key="index2">
            <VaSidebarItem
              :to="{ name: childRoute.name }"
              :active="isActiveChildRoute(childRoute)"
              :active-color="activeColor"
              :text-color="textColor(childRoute)"
              :aria-label="`Visit ${t(route.displayName)}`"
              hover-opacity="0.10"
            >
              <VaSidebarItemContent class="py-3 pr-2 pl-11">
                <VaSidebarItemTitle class="leading-5 font-semibold">
                  {{ t(childRoute.displayName) }}
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </div>
        </template> -->
      </VaCollapse>
    </VaAccordion>
    <div class="h-full">
    </div>
    <div class="flex items-center gap-8 flex-wrap justify-center">
      <a href="https://false-caravel-f1d.notion.site/Tutoriels-OBEIA-Yobino-112f9c6e44cf81eeae3df9cd3c31e7e6?pvs=4" target="_blank">
        <VaButton
        round
        preset="secondary"
        icon="live_help"
        class="mb-4"
        style="padding: 4px;"
        ><span style="font-size: 14px;margin-left: 6px;"> Support</span></VaButton>
      </a>
    </div>
  </VaSidebar>
</template>
<script lang="ts">
import { defineComponent, watch, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useColors, VaDivider } from 'vuestic-ui'
import navigationRoutes, { type INavigationRoute } from './NavigationRoutes'
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '../../stores/global-store'
import { useUserStore } from '../../stores/user-store'

export default defineComponent({
  name: 'Sidebar',
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
    sidebarWidth: {type: String, default: '280px'},
    sidebarMinimizedWidth: {type: String, default: '0'}
  },
  emits: ['update:visible'],

  setup: (props, { emit }) => {
    const user = useUserStore()
    const { getColor, colorToRgba } = useColors()
    const route = useRoute()
    const { t } = useI18n()

    const GlobalStore = useGlobalStore()
    const { isSidebarMinimized } = storeToRefs(GlobalStore)

    const value = ref<boolean[]>([])

    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    })

    const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name

    const routeHasActiveChild = (section: INavigationRoute) => {
      //if (!section.children) {
        return route.name.endsWith(`${section.name}`)
      //}

      //return section.children.some(({ name }) => route.path.endsWith(`${name}`))
    }

    const setActiveExpand = () => (value.value = navigationRoutes.routes.map((route: INavigationRoute) => routeHasActiveChild(route)))

    //const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
    const color = computed(() => getColor('background-secondary'))
    const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1))
    const siteUid = computed(() => user.selectedSite)

    const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'secondary')
    const textColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'textPrimary')
    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

    watch(() => route.fullPath, setActiveExpand, { immediate: true })

    return {
      siteUid,
      writableVisible,
      //sidebarWidth,
      isSidebarMinimized,
      value,
      color,
      activeColor,
      navigationRoutes,
      routeHasActiveChild,
      isActiveChildRoute,
      t,
      iconColor,
      textColor,
      arrowDirection,
    }
  },
})
</script>
<style lang="scss" scoped>

::v-deep(.va-divider){
  margin-top: 0px;
  margin-bottom: 0px;
}

</style>
